import React, { useRef, useEffect } from 'react';
import { useLocation, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import LayoutDefault from './layouts/LayoutDefault';
import Home from './views/Home';
import firebaseApp from './utils/firebase';

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GA_CODE);

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

function App() {
  const childRef = useRef();
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    document.body.classList.add('is-loaded');
    childRef.current.init();
    trackPage(page);
  }, [location]);

  return (
    <ScrollReveal ref={childRef}>
      {() => (
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
        </Switch>
      )}
    </ScrollReveal>
  );
}
export { firebaseApp };

export default App;
