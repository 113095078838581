import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import FeatureTileIcon1 from '../../assets/images/feature-tile-icon-01.svg';
import FeatureTileIcon2 from '../../assets/images/feature-tile-icon-02.svg';

function FeaturesTiles({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left',
  );

  const sectionHeader = {
    title: 'Your spiritual haven',
    paragraph: 'Nestled in the midst of the Sachi Nagar devotee community, Audarya Dham is a spiritual haven within easy walking distance from the magnificent Sri Mayapur Chandrodaya Temple of the Vedic Planetarium. Audarya Dham offers one and two bed apartments to suit your needs.',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={FeatureTileIcon1}
                      alt="Features tile icon 01"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Standards & Specifications
                  </h4>
                  <div>
                    <p className="m-0 text-xs">Smooth walls with durable quality paint</p>
                    <p className="m-0 text-xs">Vitrified floor tiles</p>
                    <p className="m-0 text-xs">European style ISO electric fittings, fans and LED lights</p>
                    <p className="m-0 text-xs">Granite countertops and stainless steel sinks</p>
                    <p className="m-0 text-xs">Large wooden French window overlooking the balcony</p>
                    <p className="m-0 text-xs">Wooden windows with stainless steel nets</p>
                    <p className="m-0 text-xs">European style commodes</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={FeatureTileIcon2}
                      alt="Features tile icon 02"
                      width={64}
                      height={64}
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Facilities
                  </h4>
                  <div>
                    <p className="m-0 text-xs">Garden area with solar lights</p>
                    <p className="m-0 text-xs">Lift, Gated security, Cycle and bike parking</p>
                    <p className="m-0 text-xs">Building raised above flood level</p>
                    <p className="m-0 text-xs">Luggage storage space</p>
                    <p className="m-0 text-xs">Provision for washing machine, ACs, geysers and WiFi</p>
                    <p className="m-0 text-xs">Inverter and battery power backup</p>
                    <p className="m-0 text-xs">Apartment owners association</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = {
  ...SectionTilesProps.types,
};
FeaturesTiles.defaultProps = {
  ...SectionTilesProps.defaults,
};

export default FeaturesTiles;
