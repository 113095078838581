import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import Button from '../elements/Button';
import ButtonGroup from '../elements/ButtonGroup';

const subject = 'Enquiry for unit at Audarya Dham';

function Cta({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) {
  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split',
  );

  const [email, setEmail] = React.useState('');

  function validateEmail(_email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(_email).toLowerCase());
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan">
            <h3 className="m-0">
              For bookings and inquiries?
            </h3>
          </div>
          <div className="cta-action">
            <ButtonGroup>
              <Input
                id="newsletter"
                type="email"
                label="Subscribe"
                labelHidden
                hasIcon="right"
                placeholder="Your email address"
                onChange={(e) => {
                  if (validateEmail(e.target.value)) {
                    setEmail(e.target.value);
                  }
                }}
              >
                <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#2e615f" />
                </svg>
              </Input>
              <Button
                className="button-primary"
                disabled={email === ''}
                onClick={() => {
                  window.open(`mailto:audaryadhammayapur@gmail.com?from=${email}subject=${subject}`, '_self');
                }}
              >
                Send Email
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};
Cta.defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

export default Cta;
