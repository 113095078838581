import React from 'react';
import classNames from 'classnames';
import {
  getStorage, ref, listAll, getDownloadURL,
} from 'firebase/storage';
import ReactImageGallery from 'react-image-gallery';
import moment from 'moment';
import SectionHeader from './partials/SectionHeader';
import { SectionTilesProps } from '../../utils/SectionProps';
import firebaseApp from '../../utils/firebase';

const storage = getStorage(firebaseApp);

function MediaTiles({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const sectionHeader = {
    title: 'Site Videos',
    // eslint-disable-next-line max-len
    // paragraph: 'Nestled in the midst of the Sachi Nagar devotee community, Audarya Dham is a spiritual haven within easy walking distance from the magnificent Sri Mayapur Chandrodaya Temple of the Vedic Planetarium. Audarya Dham offers one and two bed apartments to suit your needs.'
  };

  const [urls, setUrls] = React.useState([]);

  React.useEffect(() => {
    const listRef = ref(storage, 'media');
    listAll(listRef)
      .then((res) => {
        Promise.all(
          res.items
            .map((item) => getDownloadURL(item).then((_url) => ({ name: item.name, url: _url }))),
        )
          .then((_urls) => {
            const originals = _urls.filter((_url) => !_url.name.startsWith('thumb_'));
            const thumbs = _urls.filter((_url) => _url.name.startsWith('thumb_'));
            setUrls(
              originals
                .map((original) => {
                  const thumbName = `thumb_${original.name}.jpg`;
                  const date = new Date(
                    parseInt(original.name.substring(0, 4), 10),
                    parseInt(original.name.substring(4, 6), 10) - 1,
                    1,
                  );

                  const item = {
                    thumbnail: thumbs.find((thumb) => thumb.name === thumbName)?.url,
                    original: original.url,
                    description: `${moment(date).format("MMM 'YY")} - ${original.name.substring(13).split('.')[0]}`,
                    video: original.name.includes('_Video_'),
                  };
                  if (item.video) {
                    item.renderItem = (_item) => (
                      <div>
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <video className="image-gallery-image" style={{ height: 600 }} controls>
                          <source src={_item.original} />
                        </video>
                        <span className="image-gallery-description" style={{ right: 0, left: 'initial' }}>{_item.description}</span>
                      </div>
                    );
                  }
                  return item;
                })
                .reverse(),
            );
          });
      })
      .catch(() => {

      });
  }, []);

  if (urls.length === 0) {
    return null;
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <ReactImageGallery items={urls} showFullscreenButton={false} showPlayButton={false} />
        </div>
      </div>
    </section>
  );
}

MediaTiles.propTypes = {
  ...SectionTilesProps.types,
};
MediaTiles.defaultProps = {
  ...SectionTilesProps.defaults,
};

export default MediaTiles;
