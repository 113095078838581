import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import ApartmentDetails from './ApartmentDetails';
import Image from '../elements/Image';
import HeroImage from '../../assets/images/image.png';

function BookingDetails({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) {
  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const sectionHeader = {
    title: 'Booking Details',
    // paragraph: 'Book by end of May 2021 to avail the promotional rate of Rs 3,700 per sq.ft (otherwise Rs 3,900 per sq.ft from 1st of Jun 2021).',
    paragraph: 'Book your home at Mayapur at 4500 per sq ft price'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <h3 className="mt-0 mb-12 ta-c">
            Area Specifications
          </h3>
          <div style={{ overflowX: 'auto', padding: '4px 4px' }}>
            <ApartmentDetails />
          </div>
          <p className="text-xs ta-c">*Apartment cost is calculated on super built-up area. Buyers can combine multiple units and we will confirm the feasibility.</p>
          <div className="container-xs">
            <h2 className="ta-c">Payment Instalment Details</h2>
            <div className="text-sm ta-c">
              <p className="m-0">25% at the time of booking</p>
              <p className="m-0">30% within 90 days of booking</p>
              <p className="m-0">30% within 180 days of booking</p>
              <p className="m-0">15% at the time of handing over the keys</p>
              <h5>Get 2% discount on full payment up-front</h5>
              <p className="m-0 text-xs">*Terms and conditions apply</p>
            </div>
          </div>
          <h2 className="ta-c">We welcome you to live in the transcendental land of Gauranga</h2>
          <div className="hero-figure reveal-from-bottom illustration-element-01 center-content" data-reveal-value="20px" data-reveal-delay="800">
            <Image
              className="has-shadow"
              src={HeroImage}
              alt="Hero"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

BookingDetails.propTypes = {
  ...SectionTilesProps.types,
};
BookingDetails.defaultProps = {
  ...SectionTilesProps.defaults,
};

export default BookingDetails;
