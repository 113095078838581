import React from 'react';
import Papa from 'papaparse';

const formatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function ApartmentDetails() {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    Papa.parse('https://docs.google.com/spreadsheets/d/18XEJUqrk-2EnjhbLYJrKqp0_dwF0nDwmmX8FzIPbuvg/pub?output=csv', {
      download: true,
      header: true,
      complete: (result) => {
        setData(result.data);
      },
    });
  }, []);

  if (data.length === 0) {
    return null;
  }

  // const columns = ['Floor', 'Apart #*', 'Super Build*', 'Price', 'Price (Promo)', 'Status'];
  const columns = ['Floor', 'Apart #*', 'Super Build*', 'Price', 'Status'];

  return (
    <div>
      <table>
        <thead>
          <tr>
            {columns.map((key) => <th key={key}>{key}</th>)}
          </tr>
        </thead>
        <tbody>
          {data.slice(0, data.length - 1).map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={index}>
              {columns.map((key) => (
                <td key={key} 
                // style={{ textDecoration: key === 'Price' ? 'line-through' : undefined }}
                >
                  {key.includes('Price') ? formatter.format(row[key]) : row[key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ApartmentDetails;
