import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Logo from './partials/Logo';

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
};

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
};

function Footer({
  className,
  topOuterDivider,
  topDivider,
  ...props
}) {
  const classes = classNames(
    'site-footer center-content-mobile',
    topOuterDivider && 'has-top-divider',
    className,
  );

  return (
    <footer {...props} className={classes}>
      <div className="container">
        <div className={classNames('site-footer-inner', topDivider && 'has-top-divider')}>
          <Logo />
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <div className="text-xs text-color-secondary">
              <p className="m-0">Project Manager</p>
              <p className="m-0">Bhaktivinodanuga Das</p>
              <a href="tel:+919434951901" className="m-0">+91 94349 51901</a>
            </div>
            <div className="footer-copyright text-color-secondary">
              ©
              {new Date().getFullYear()}
              {' '}
              Audarya Dham Pvt. Ltd. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;
