import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import BookingDetails from '../components/sections/BookingDetails';
import Cta from '../components/sections/Cta';
import MediaTiles from '../components/sections/MediaTiles';

function Home() {
  return (
    <>
      <Hero />
      <FeaturesTiles />
      <FeaturesSplit invertMobile topDivider imageFill />
      <MediaTiles />
      <BookingDetails topDivider />
      <Cta split />
    </>
  );
}

export default Home;
