import React from 'react';
import Footer from '../components/layout/Footer';

function LayoutDefault({ children }) {
  return (
    <>
      <main className="site-content">
        {children}
      </main>
      <Footer />
    </>
  );
}

export default LayoutDefault;
