import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyDInyqaN5SuXjDtWuP7GknuWJxkVXBTRn4',
  authDomain: 'audaryadham.firebaseapp.com',
  projectId: 'audaryadham',
  storageBucket: 'audaryadham.appspot.com',
  messagingSenderId: '809855607826',
  appId: '1:809855607826:web:8b8127b2401f6e511824cb',
};
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
