import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import GroundImage from '../../assets/images/ground.png';
import OneBhkImage from '../../assets/images/one.png';
import TwoBhkImage from '../../assets/images/two.png';

function FeaturesSplit({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) {
  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top',
  );

  const sectionHeader = {
    title: 'Floor Plans & Area Specifications',
    paragraph: 'Nestled in the midst of the Sachi Nagar devotee community, Audarya Dham is a spiritual haven within easy walking distance from the magnificent Sri Mayapur Chandrodaya Temple of the Vedic Planetarium. Audarya Dham offers one and two bed apartments to suit your needs.',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  Ground Floor Apartments
                </h3>
              </div>
              <div
                className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill',
                )
}
                data-reveal-container=".split-item"
              >
                <Image
                  src={GroundImage}
                  alt="Ground Floor Apartments"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  One Bedroom Apartments
                </h3>
              </div>
              <div
                className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill',
                )
}
                data-reveal-container=".split-item"
              >
                <Image
                  src={OneBhkImage}
                  alt="One Bedroom Apartments"
                  width={528}
                  height={396}
                />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  Two Bedroom Apartments
                </h3>
              </div>
              <div
                className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill',
                )
}
                data-reveal-container=".split-item"
              >
                <Image
                  src={TwoBhkImage}
                  alt="Two Bedroom Apartments"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = {
  ...SectionSplitProps.types,
};
FeaturesSplit.defaultProps = {
  ...SectionSplitProps.defaults,
};

export default FeaturesSplit;
