import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/scss/style.scss';
import 'react-image-gallery/styles/css/image-gallery.css';

const history = createBrowserHistory();

// const getFirebaseConfig = fetch('/__/firebase/init.json')
//   .then((response) => response.json())
//   .then((response) => {
//     firebase.initializeApp(response);
//   });

// getFirebaseConfig
//   .then(() => {
ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
// })
// .catch((err) => console.log(err));
